// import EditorJS from '@editorjs/editorjs'
// import Paragraph from '@editorjs/paragraph';
// import Header  from '@editorjs/header';
// import Embed from '@editorjs/embed';

window.EditorJS=require('@editorjs/editorjs')
window.Paragraph=require('@editorjs/paragraph')
window.Header=require('@editorjs/header')
window.Embed=require('@editorjs/embed')
//  console.log("aziz")
//  console.log(EditorJS)

// export default {EditorJS,Paragraph,Header,Embed}